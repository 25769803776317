import React, { Component } from "react";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import "../../css/general.css"
import "../../css/colors.css"
import "../../css/texts.css"
import DiseasCard from "./diseasCard";
import { format } from "react-string-format";

class Disease {
    constructor() {
      this.name = "";
      this.diseasId = "";
      this.diagnosis = "";
      this.diagnosisId = "";
      this.classname = "";
    }
}


class DiseasesList extends Component {
  
  state = {
    usertype: ""
  };

  componentDidMount() {

      this.setState({
          usertype: this.props.usertype
      })
  }

  getClassname(diseases, bad) {
    let glaucoma = 'diseas-card-not-analyzed';
    let armd = 'diseas-card-not-analyzed';
    let mtmdr = 'diseas-card-not-analyzed';
    let hr = 'diseas-card-not-analyzed';
    let p = 'diseas-card-not-analyzed';
    let rvo = 'diseas-card-not-analyzed';
    let dme = 'diseas-card-not-analyzed';
    let lipidTG = 'diseas-card-not-analyzed';
    let lipidLDL = 'diseas-card-not-analyzed';
    let smoking = 'diseas-card-not-analyzed';
    let iop = 'diseas-card-not-analyzed';
    let dementiafii = 'diseas-card-not-analyzed';
    let dementiapmi = 'diseas-card-not-analyzed';
    let dementiarisk = 'diseas-card-not-analyzed';
    let cataract = 'diseas-card-not-analyzed';
    let diabetes = 'diseas-card-not-analyzed';
    let drprediction = 'diseas-card-not-analyzed';
    let laser = 'diseas-card-not-analyzed';

    let chAge = 'diseas-card-not-analyzed';
    let chGender = 'diseas-card-not-analyzed';
    let chFieled = 'diseas-card-not-analyzed';

    let LipidTGPrediction = null;
    let LipidLDLPrediction = null;
    let smokingPrediction = null;
    let iopPrediction = null;

    let dementiafiiPrediction = null;
    let dementiapmiPrediction = null;
    let dementiariskPrediction = null;

    let SystolicPred = null;
    let DiastolicPred = null;

    let risk_group = null;
    let risk_percent = null;


    if(diseases && bad == false) {
        for (const [index, disease] of diseases.entries()) {
            if(disease.diseasename == "diagDRPrediction") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);
                    risk_group = chjsonresults.risk_group;
                    risk_percent = (chjsonresults.risk_percent * 100);
                }
                break;
            }
            if(disease.diseasename == "diagLipid") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);
                    LipidTGPrediction = chjsonresults.predictionTG;
                    LipidLDLPrediction = chjsonresults.predictionLDL;
                }
            }
            if(disease.diseasename == "diagHypertensiveRetinopathy") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);

                    SystolicPred = chjsonresults.systolic_prediction;
                    DiastolicPred = chjsonresults.diastolic_prediction;
                } else {
                }
            }
            if(disease.diseasename == "diagSmoking") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);
                    smokingPrediction = chjsonresults.prediction_indedx;
                }
            }
            if(disease.diseasename == "diagIOP") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);
                    iopPrediction = chjsonresults.prediction;
                }
            }
            if(disease.diseasename == "diagDementia") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);
                    dementiafiiPrediction = chjsonresults.fi_prediction_index;
                    dementiapmiPrediction = chjsonresults.pros_prediction_index;
                    if(dementiafiiPrediction == 0 && dementiapmiPrediction == 0) {
                        dementiariskPrediction = 0
                    } else if(dementiafiiPrediction == 0 && dementiapmiPrediction == 1) {
                        dementiariskPrediction = 1
                    } else if(dementiafiiPrediction == 0 && dementiapmiPrediction == 2) {
                        dementiariskPrediction = 2
                    } else if(dementiafiiPrediction == 1 && dementiapmiPrediction == 0) {
                        dementiariskPrediction = 3
                    } else if(dementiafiiPrediction == 1 && dementiapmiPrediction == 1) {
                        dementiariskPrediction = 4
                    } else if(dementiafiiPrediction == 1 && dementiapmiPrediction == 2) {
                        dementiariskPrediction = 5
                    } else if(dementiafiiPrediction == 2 && dementiapmiPrediction == 0) {
                        dementiariskPrediction = 6
                    } else if(dementiafiiPrediction == 2 && dementiapmiPrediction == 1) {
                        dementiariskPrediction = 7
                    } else if(dementiafiiPrediction == 2 && dementiapmiPrediction == 2) {
                        dementiariskPrediction = 8
                    }
                }
            }
        }
        for (const [index, disease] of diseases.entries()) {
            switch(disease.diseasename) {
                case "diagDiabeticRetinopathy":
                    if(disease.diagnosis == 5) {
                        mtmdr = 'diseas-card-sick';
                    } else {
                        mtmdr = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        mtmdr = 'diseas-card-sick';
                    }
                    break;
                case "diagAgeRelatedMacularDegeneration":
                    if(disease.diagnosis == 5) {
                        armd = 'diseas-card-sick';
                    } else {
                        armd = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        armd = 'diseas-card-sick';
                    }
                    break;
                case "diagHypertensiveRetinopathy":
                    if(SystolicPred == null || DiastolicPred == null) {
                        hr = 'diseas-card-not-analyzed';
                    } else {
                        if(SystolicPred.toLowerCase() == 'hypertension' || DiastolicPred.toLowerCase() == 'hypertension' ) {
                            hr = 'diseas-card-sick';
                        } else if(SystolicPred.toLowerCase() == 'inconclusive' || DiastolicPred.toLowerCase() == 'inconclusive' ) {
                            hr = 'diseas-card-medium';
                        } else {
                            hr = 'diseas-card-healty';
                        }
                    }
                    break;
                case "diagPapilledema":
                    if(disease.diagnosis == 5) {
                        p = 'diseas-card-sick';
                    } else {
                        p = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        p = 'diseas-card-sick';
                    }
                    break;
                case "diagRetinalVascularOcclusion":
                    if(disease.diagnosis == 5) {
                        rvo = 'diseas-card-sick';
                    } else {
                        rvo = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        rvo = 'diseas-card-sick';
                    }
                    break;
                case "diagDME":
                    if(disease.diagnosis == 5) {
                        dme = 'diseas-card-sick';
                    } else {
                        dme = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        dme = 'diseas-card-sick';
                    }
                    break;
                case "diagLipid":
                    if(LipidTGPrediction == null || LipidLDLPrediction == null) {
                        lipidTG = 'diseas-card-not-analyzed';
                        lipidLDL = 'diseas-card-not-analyzed';
                    } else {
                        if(LipidTGPrediction == 'Negative') {
                            lipidTG = 'diseas-card-healty';
                        } else if(LipidTGPrediction == 'Borderline') {
                            lipidTG = 'diseas-card-medium';
                        } else if(LipidTGPrediction == 'Positive') {
                            lipidTG = 'diseas-card-sick';
                        } else if(LipidTGPrediction == 'VeryPositive') {
                            lipidTG = 'diseas-card-sick';
                        }

                        if(LipidLDLPrediction == 'Negative') {
                            lipidLDL = 'diseas-card-healty';
                        } else if(LipidLDLPrediction == 'Borderline') {
                            lipidLDL = 'diseas-card-medium';
                        } else if(LipidLDLPrediction == 'Positive') {
                            lipidLDL = 'diseas-card-sick';
                        }
                    }
                    break;
                case "diagGlaucoma":
                    if(disease.diagnosis == 5) {
                        glaucoma = 'diseas-card-sick';
                    } else {
                        glaucoma = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        glaucoma = 'diseas-card-sick';
                    } else if(disease.diagnosis == 3) {
                        glaucoma = 'diseas-card-not-analyzed';
                    }
                    break;
                case "diagAge":
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        if(chjsonresults.age_prediction) {
                            chGender = 'diseas-card-healty';
                        }
                    }
                    break;
                case "diagGender":
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        if(chjsonresults.gender_prediction) {
                            chGender = 'diseas-card-healty';
                        }
                    }
                    break;
                case "diagField":
                    if(disease.chresults != null) {
                        chFieled = 'diseas-card-healty';
                    }
                    break;

                case "diagCataract":
                    if(disease.diagnosis == 5) {
                        cataract = 'diseas-card-sick';
                    } else {
                        cataract = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        cataract = 'diseas-card-sick';
                    }
                    break;
                case "diagDiabetes":
                    if(disease.diagnosis == 5 || risk_group == null || risk_percent == null) {
                        diabetes = 'diseas-card-sick';
                    } else if(disease.diagnosis == 1) {
                        let thedrp = format(componentsStrings.aeyedetailstable_drprediction, risk_group, risk_percent);
                        diabetes = 'diseas-card-sick';
                        if(risk_group.toLowerCase() == 'low') {
                            drprediction = 'diseas-card-healty';
                        } else if(risk_group.toLowerCase() == 'medium') {
                            drprediction = 'diseas-card-medium';
                        } else {
                            drprediction = 'diseas-card-sick';
                        }
                    } else {
                        if(disease.chresults != null) {
                            const chjsonresults = JSON.parse(disease.chresults);
                            if(chjsonresults.prediction == "borderline") {
                                diabetes = 'diseas-card-medium';
                            } else {
                                diabetes = 'diseas-card-healty';
                            }
                        } else {
                            diabetes = 'diseas-card-healty';
                        }
                    }
                    if(disease.diagnosis == -1) {
                        diabetes = 'diseas-card-healty';
                        drprediction = 'diseas-card-healty';
                    }
                    break;
                case "diagSmoking":
                    if(smokingPrediction == null) {
                        smoking ='diseas-card-sick'
                    } else {
                        if(smokingPrediction == 0) {
                            smoking = 'diseas-card-healty';
                        } else if(smokingPrediction == 1) {
                            smoking = 'diseas-card-healty';
                        } else if(smokingPrediction == 2) {
                            smoking = 'diseas-card-inconclusive';
                        } else if(smokingPrediction == 3) {
                            smoking = 'diseas-card-sick';
                        } else if(smokingPrediction == 4) {
                            smoking = 'diseas-card-sick';
                        }
                    }
                    break;
                case "diagIOP":
                        if(iopPrediction == null) {
                            iop ='diseas-card-sick'
                        } else {
                            if(iopPrediction == 0) {
                                iop = 'diseas-card-healty';
                            } else if(iopPrediction == 1) {
                                iop = 'diseas-card-sick';
                            } else if(iopPrediction == -1) {
                                iop = 'diseas-card-inconclusive';
                            }
                        }
                        break;
                case "diagDementia":
                        if(dementiafiiPrediction == null || dementiapmiPrediction == null) {
                            dementiafii ='diseas-card-sick'
                            dementiapmi ='diseas-card-sick'
                            dementiarisk ='diseas-card-sick'
                        } else {
                            if(dementiafiiPrediction == 0) {
                                dementiafii = 'diseas-card-healty';
                            } else if(dementiafiiPrediction == 1) {
                                dementiafii = 'diseas-card-sick';
                            } else if(dementiafiiPrediction == 2) {
                                dementiafii = 'diseas-card-inconclusive';
                            }
                            if(dementiapmiPrediction == 0) {
                                dementiapmi = 'diseas-card-healty';
                            } else if(dementiapmiPrediction == 1) {
                                dementiapmi = 'diseas-card-sick';
                            } else if(dementiapmiPrediction == 2) {
                                dementiapmi = 'diseas-card-inconclusive';
                            }
                            if(dementiariskPrediction == 0 || dementiariskPrediction == 1 || dementiariskPrediction == 2 || dementiariskPrediction == 6 || dementiariskPrediction == 8) {
                                dementiarisk = 'diseas-card-healty';
                            } else if(dementiariskPrediction == 3 || dementiariskPrediction == 4 || dementiariskPrediction == 5 || dementiariskPrediction == 7) {
                                dementiarisk = 'diseas-card-sick';
                            }
                        }
                        break;
                    case "diagLaser":
                    if(disease.diagnosis == 5) {
                        laser = 'diseas-card-sick';
                    } else {
                        laser = (disease.diagnosis == 1) ? 'diseas-card-sick' : 'diseas-card-healty';
                    }
                    if(disease.diagnosis == -1) {
                        laser = 'diseas-card-sick';
                    }
                    break;
                }
        }
    }
    return [chFieled, chAge, chGender, glaucoma, armd, mtmdr, hr, p, rvo, cataract, diabetes, drprediction, dme, laser, lipidTG, lipidLDL, smoking, iop, dementiafii, dementiapmi, dementiarisk];
  }

  getDiseasesDescription(diseases, bad) {
      let mtmdr = 'Not Analyzed';
      let glaucoma = 'Not Analyzed';
      let armd = 'Not Analyzed';      
      let hr = 'Not Analyzed';
      let hr_Systolic = 'Not Analyzed';
      let hr_Diastolic = 'Not Analyzed';
      let p = 'Not Analyzed';
      let rvo = 'Not Analyzed';
      let dme = 'Not Analyzed';
      let lipidTG = 'Not Analyzed';
      let lipidLDL = 'Not Analyzed';
      let smoking = 'Not Analyzed';
      let iop = 'Not Analyzed';
      let dementiafii = 'Not Analyzed';
      let dementiapmi = 'Not Analyzed';
      let dementiarisk = 'Not Analyzed';
      let cataract = 'Not Analyzed';
      let diabetes = 'Not Analyzed';
      let drprediction = 'Not Analyzed';
      let other = 'Not Analyzed';
      let laser = 'Not Analyzed';

      let chAge = 'Not Analyzed';
      let chGender = 'Not Analyzed';
      let chFieled = 'Not Analyzed';

      let LipidTGPrediction = null;
      let LipidLDLPrediction = null;
      let smokingPrediction = null;
      let iopPrediction = null;
      let dementiafiiPrediction = null;
      let dementiapmiPrediction = null;
      let dementiariskPrediction = null;
  
      let SystolicPredNumber = null;
      let SystolicPred = null;
      let DiastolicPredNumber = null;
      let DiastolicPred = null;

      let risk_group = null;
      let risk_percent = null;
      let counter = 0; 
    
      if(diseases && bad == false) {
          for (const [index, disease] of diseases.entries()) {
              if(disease.diseasename == "diagDRPrediction") {
                  if(disease.chresults != null) {
                      const chjsonresults = JSON.parse(disease.chresults);
                      risk_group = chjsonresults.risk_group;
                      risk_percent = (chjsonresults.risk_percent * 100);
                  }
              }
              if(disease.diseasename == "diagLipid") {
                if(disease.chresults != null) {
                    const chjsonresults = JSON.parse(disease.chresults);

                    LipidTGPrediction = chjsonresults.predictionTG;
                    LipidLDLPrediction = chjsonresults.predictionLDL;
                } else {
                }
              }
              if(disease.diseasename == "diagHypertensiveRetinopathy") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);

                        SystolicPredNumber = chjsonresults.systolic_prediction_number;
                        SystolicPred = chjsonresults.systolic_prediction;
                        DiastolicPredNumber = chjsonresults.diastolic_prediction_number;
                        DiastolicPred = chjsonresults.diastolic_prediction;
                    } else {
                    }
                }
                if(disease.diseasename == "diagSmoking") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        smokingPrediction = chjsonresults.prediction_indedx;
                    }
                }
                if(disease.diseasename == "diagIOP") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        iopPrediction = chjsonresults.prediction;
                    }
                }
                if(disease.diseasename == "diagDementia") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        dementiafiiPrediction = chjsonresults.fi_prediction_index;
                        dementiapmiPrediction = chjsonresults.pros_prediction_index;
                        if(dementiafiiPrediction == 0 && dementiapmiPrediction == 0) {
                            dementiariskPrediction = 0
                        } else if(dementiafiiPrediction == 0 && dementiapmiPrediction == 1) {
                            dementiariskPrediction = 1
                        } else if(dementiafiiPrediction == 0 && dementiapmiPrediction == 2) {
                            dementiariskPrediction = 2
                        } else if(dementiafiiPrediction == 1 && dementiapmiPrediction == 0) {
                            dementiariskPrediction = 3
                        } else if(dementiafiiPrediction == 1 && dementiapmiPrediction == 1) {
                            dementiariskPrediction = 4
                        } else if(dementiafiiPrediction == 1 && dementiapmiPrediction == 2) {
                            dementiariskPrediction = 5
                        } else if(dementiafiiPrediction == 2 && dementiapmiPrediction == 0) {
                            dementiariskPrediction = 6
                        } else if(dementiafiiPrediction == 2 && dementiapmiPrediction == 1) {
                            dementiariskPrediction = 7
                        } else if(dementiafiiPrediction == 2 && dementiapmiPrediction == 2) {
                            dementiariskPrediction = 8
                        }
                    }
                }
            }
            for (const [index, disease] of diseases.entries()) {
              switch(disease.diseasename) {
                  case "diagDiabeticRetinopathy":
                      if(disease.diagnosis == 5) {
                          mtmdr =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                        counter++;
                        mtmdr = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_drsuspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          mtmdr =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagAgeRelatedMacularDegeneration":
                      if(disease.diagnosis == 5) {
                          armd =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                        counter++;
                        armd = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          armd =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagHypertensiveRetinopathy":
                    if(SystolicPredNumber == null || SystolicPred == null || DiastolicPredNumber == null || DiastolicPred == null) {
                        hr_Systolic =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        hr_Diastolic =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        hr =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                    } else {
                        counter++;
                        let systolicclass = '';
                        let systolicclassname = '';
                        if(SystolicPred.toLowerCase() == 'hypertension') {
                            systolicclass = 'High Risk';
                            systolicclassname = 'red';
                        } else if(SystolicPred.toLowerCase() == 'inconclusive') {
                            systolicclass = 'Borderline Risk';
                            systolicclassname = 'orange';
                        } else {
                            systolicclassname = 'green';
                            systolicclass = 'Low Risk';
                        }
                        let distolicclass = '';
                        let distolicclassname = '';
                        if(DiastolicPred.toLowerCase() == 'hypertension' ) {
                            distolicclassname = 'red';
                            distolicclass = 'High Risk';
                        } else if(DiastolicPred.toLowerCase() == 'inconclusive' ) {
                            distolicclassname = 'orange';
                            distolicclass = 'Borderline Risk';
                        } else {
                            distolicclassname = 'green';
                            distolicclass = 'Low Risk';
                        }

                        hr = <label className={systolicclassname}>{(SystolicPredNumber | 0) + "/" + (DiastolicPredNumber | 0)}</label>
                        hr_Systolic =<label className={systolicclassname}>{systolicclass}</label>
                        hr_Diastolic =<label className={distolicclassname}>{distolicclass}</label>
                    }
                    break;
                  case "diagPapilledema":
                      if(disease.diagnosis == 5) {
                          p =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                        counter++;
                        p = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          p =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagRetinalVascularOcclusion":
                      if(disease.diagnosis == 5) {
                          rvo =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                        counter++;
                        rvo = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          rvo =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                    case "diagDME":
                        if(disease.diagnosis == 5) {
                            dme =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            counter++;
                            dme = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            dme =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagGlaucoma":
                      if(disease.diagnosis == 5) {
                          glaucoma =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                        counter++;
                        glaucoma = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          glaucoma =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagAge":
                      if(disease.chresults != null) {
                        counter++;
                        const chjsonresults = JSON.parse(disease.chresults);
                        let theage = parseFloat(chjsonresults.age_prediction).toFixed(1)
                        chAge = <label className="green">{theage}</label>
                      }
                      break;
                  case "diagGender":
                      if(disease.chresults != null) {
                        counter++;
                        const chjsonresults = JSON.parse(disease.chresults);
                        chGender = <label className="green">{chjsonresults.gender_prediction}</label>
                      }
                      break;
                  case "diagField":
                      if(disease.chresults != null) {
                          chFieled = JSON.parse(disease.chresults);
                      }
                      break;
                  case "diagOther":
                      if(disease.diagnosis == 5) {
                          other =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                          other = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          other =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagCataract":
                      if(disease.diagnosis == 5) {
                          cataract =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else {
                        counter++;
                        cataract = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                      }
                      if(disease.diagnosis == -1) {
                          cataract =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagLipid":
                        if(LipidTGPrediction == null || LipidLDLPrediction == null) {
                            lipidTG =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                            lipidLDL =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            counter++;
                            if(LipidTGPrediction == 'Negative') {
                                lipidTG = <label className="green">{'Low Risk'}</label>;
                            } else if(LipidTGPrediction == 'Borderline') {
                                lipidTG = <label className="orange">{'Borderline'}</label>;
                            }  else if(LipidTGPrediction == 'Positive') {
                                lipidTG = <label className="red">{'High Risk'}</label>;
                            }  else if(LipidTGPrediction == 'VeryPositive') {
                                lipidTG = <label className="red">{'Very High Risk'}</label>;
                            }
    
                            if(LipidLDLPrediction == 'Negative') {
                                lipidLDL = <label className="green">{'Low Risk'}</label>;
                            } else if(LipidLDLPrediction == 'Borderline') {
                                lipidLDL = <label className="orange">{'Borderline'}</label>;
                            } else if(LipidLDLPrediction == 'Positive') {
                                lipidLDL = <label className="red">{'High Risk'}</label>;
                            }
                        }
                        break;
                    case "diagSmoking":
                            if(smokingPrediction == null) {
                                smoking =<label className="red">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                            } else {
                                if(smokingPrediction == 0) {
                                    smoking = <label className="green">{componentsStrings.aeyedetailstable_smoking_0}</label>
                                } else if(smokingPrediction == 1) {
                                    smoking = <label className="green">{componentsStrings.aeyedetailstable_smoking_1}</label>
                                } else if(smokingPrediction == 2) {
                                    smoking = <label className="lightblue">{componentsStrings.aeyedetailstable_smoking_2}</label>
                                } else if(smokingPrediction == 3) {
                                    smoking = <label className="red">{componentsStrings.aeyedetailstable_smoking_3}</label>
                                } else if(smokingPrediction == 4) {
                                    smoking = <label className="red">{componentsStrings.aeyedetailstable_smoking_4}</label>
                                }
                            }
                        break;
                    case "diagIOP":
                            if(iopPrediction == null) {
                                iop = <label className="red">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                            } else {
                                if(iopPrediction == 0) {
                                    iop = <label className="green">{componentsStrings.aeyedetailstable_iop_0}</label>
                                } else if(iopPrediction == 1) {
                                    iop = <label className="red">{componentsStrings.aeyedetailstable_iop_1}</label>
                                } else if(iopPrediction == -1) {
                                    iop = <label className="lightblue">{componentsStrings.aeyedetailstable_iop_11}</label>
                                }
                            }
                        break;
                    case "diagDementia":
                            if(dementiafiiPrediction == null || dementiapmiPrediction == null) {
                                dementiafii = <label className="red">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                                dementiapmi = <label className="red">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                                dementiarisk = <label className="red">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                            } else {
                                if(dementiafiiPrediction == 0) {
                                    dementiafii = <label className="green">{componentsStrings.aeyedetailstable_dementiafii_0}</label>
                                } else if(dementiafiiPrediction == 1) {
                                    dementiafii = <label className="red">{componentsStrings.aeyedetailstable_dementiafii_1}</label>
                                } else if(dementiafiiPrediction == 2) {
                                    dementiafii = <label className="lightblue">{componentsStrings.aeyedetailstable_dementiafii_2}</label>
                                }
                                if(dementiapmiPrediction == 0) {
                                    dementiapmi = <label className="green">{componentsStrings.aeyedetailstable_dementiapmi_0}</label>
                                } else if(dementiapmiPrediction == 1) {
                                    dementiapmi = <label className="red">{componentsStrings.aeyedetailstable_dementiapmi_1}</label>
                                } else if(dementiapmiPrediction == 2) {
                                    dementiapmi = <label className="lightblue">{componentsStrings.aeyedetailstable_dementiapmi_2}</label>
                                }
                                if(dementiariskPrediction == 0) {
                                    dementiarisk = <label className="green">{componentsStrings.aeyedetailstable_dementiarisk_0}</label>
                                } else if(dementiariskPrediction == 1) {
                                    dementiarisk = <label className="green">{componentsStrings.aeyedetailstable_dementiarisk_1}</label>
                                } else if(dementiariskPrediction == 2) {
                                    dementiarisk = <label className="green">{componentsStrings.aeyedetailstable_dementiarisk_2}</label>
                                } else if(dementiariskPrediction == 3) {
                                    dementiarisk = <label className="red">{componentsStrings.aeyedetailstable_dementiarisk_3}</label>
                                } else if(dementiariskPrediction == 4) {
                                    dementiarisk = <label className="red">{componentsStrings.aeyedetailstable_dementiarisk_4}</label>
                                } else if(dementiariskPrediction == 5) {
                                    dementiarisk = <label className="red">{componentsStrings.aeyedetailstable_dementiarisk_5}</label>
                                } else if(dementiariskPrediction == 6) {
                                    dementiarisk = <label className="green">{componentsStrings.aeyedetailstable_dementiarisk_6}</label>
                                } else if(dementiariskPrediction == 7) {
                                    dementiarisk = <label className="red">{componentsStrings.aeyedetailstable_dementiarisk_7}</label>
                                } else if(dementiariskPrediction == 8) {
                                    dementiarisk = <label className="green">{componentsStrings.aeyedetailstable_dementiarisk_8}</label>
                                }
                            }
                            break;
                    case "diagDiabetes":
                      if(disease.diagnosis == 5 || risk_group == null || risk_percent == null) {
                          diabetes =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                      } else if(disease.diagnosis == 1) {
                          counter++;
                          let thedrp = format(componentsStrings.aeyedetailstable_drprediction, risk_group, risk_percent);
                          diabetes = <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label>
                          if(risk_group.toLowerCase() == 'low') {
                            counter++;
                            drprediction = <label className="green">{thedrp}</label>
                          } else if(risk_group.toLowerCase() == 'medium') {
                            counter++;
                            drprediction = <label className="orange">{thedrp}</label>
                          } else {
                            counter++;
                            drprediction = <label className="red">{thedrp}</label>
                          }
                      } else {
                        counter++;
                        if(disease.chresults != null) {
                            const chjsonresults = JSON.parse(disease.chresults);
                            if(chjsonresults.prediction == "borderline") {
                                diabetes = <label className="orange">{componentsStrings.aeyedetailstable_borderlinefinding}</label>
                            } else {
                                diabetes = <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                            }
                        } else {
                            diabetes = <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                      }
                      if(disease.diagnosis == -1) {
                        diabetes =<label>{messages.table_desisdetails_dataencrypted}</label>
                        drprediction =<label>{messages.table_desisdetails_dataencrypted}</label>
                      }
                      break;
                  case "diagLaser":
                        if(disease.diagnosis == 5) {
                            laser =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            counter++;
                            laser = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            laser =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
              }
          }
      }
      return [chFieled, chAge, chGender, glaucoma, armd, mtmdr, hr, hr_Systolic, hr_Diastolic, p, rvo, cataract, diabetes, drprediction, dme, laser, lipidTG, lipidLDL, smoking, iop, dementiafii, dementiapmi, dementiarisk, counter];
  }
  getGlaucomaDescription(diseases, props) {
    let glaucomaR = '';
    let glaucomaL = '';
    let glaucomaP = 'Not Analyzed';

    var diseasesR = diseases.diseasesR;
    var diseasesL = diseases.diseasesL;
    var diseasesP = diseases.diseasesP;

    if(diseasesP && props.bad_p == false) {

        for (const [index, disease] of diseasesP.entries()) {
            switch(disease.diseasename) {

                case "diagGlaucoma":

                    if(disease.diagnosis == 5) {
                        glaucomaP =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                    } else {
                        glaucomaP = (disease.diagnosis == 1) ? <label className="red">{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label className="green">{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                    }
                    if(disease.diagnosis == -1) {
                        glaucomaP =<label>{messages.table_desisdetails_dataencrypted}</label>
                    }
                    if(disease.diagnosis == 3) {
                        glaucomaP = componentsStrings.aeyedetailstable_glaucoma_nodiscfound;
                    } 
                    break;
            }
        }
    }

    if(glaucomaP === componentsStrings.aeyedetailstable_glaucoma_nodiscfound) {
       
        if(diseasesR && props.bad_r == false) {

            for (const [index, disease] of diseasesR.entries()) {
                switch(disease.diseasename) {
    
                    case "diagGlaucoma":
                        if(disease.diagnosis == 3) {
                            glaucomaR = componentsStrings.label_image_iiq + ": " + componentsStrings.aeyedetailstable_glaucoma_nodiscfound + " - Right";
                            glaucomaP = glaucomaR;
                        } 
                    break;
                }
            }
        }
    
        if(diseasesL && props.bad_l == false) {
    
            for (const [index, disease] of diseasesL.entries()) {
                switch(disease.diseasename) {
    
                    case "diagGlaucoma":
                        if(disease.diagnosis == 3) {
                            glaucomaL = componentsStrings.label_image_iiq + ": " + componentsStrings.aeyedetailstable_glaucoma_nodiscfound + " - Left";
                            glaucomaP = glaucomaL;
                        } 
                    break;
                }
            }
        }

        if(glaucomaL !== '' && glaucomaR !== '') {
            glaucomaP = componentsStrings.label_image_iiq + ": " + componentsStrings.aeyedetailstable_glaucoma_nodiscfound + ' - Both';
        }
    }

    return [glaucomaP];
}


  render() {

    let glaucomaP = componentsStrings.aeyedetailstable_notanalyzed;
    let armdP = componentsStrings.aeyedetailstable_notanalyzed;
    let mtmdrP = componentsStrings.aeyedetailstable_notanalyzed;
    let hrP = componentsStrings.aeyedetailstable_notanalyzed;
    let hrP_Systolic = componentsStrings.aeyedetailstable_notanalyzed;
    let hrP_Diastolic = componentsStrings.aeyedetailstable_notanalyzed;
    let pP = componentsStrings.aeyedetailstable_notanalyzed;
    let rvoP = componentsStrings.aeyedetailstable_notanalyzed;
    let dmeP = componentsStrings.aeyedetailstable_notanalyzed;
    let lipidTGP = componentsStrings.aeyedetailstable_notanalyzed;
    let lipidLDLP = componentsStrings.aeyedetailstable_notanalyzed;
    let cataractP = componentsStrings.aeyedetailstable_notanalyzed;
    let diabetesP = componentsStrings.aeyedetailstable_notanalyzed;
    let drpredictionP = componentsStrings.aeyedetailstable_notanalyzed;
    //let dementiaP = componentsStrings.aeyedetailstable_notanalyzed;
    let cardiovascularP = componentsStrings.aeyedetailstable_notanalyzed;
    let laserP = componentsStrings.aeyedetailstable_notanalyzed;
    let smoking = componentsStrings.aeyedetailstable_notanalyzed;
    let iop = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiafii = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiapmi = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiarisk = componentsStrings.aeyedetailstable_notanalyzed;

    let glaucomaClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let armdClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let mtmdrClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let hrClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let pClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let rvoClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let dmeClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let lipidTGClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let lipidLDLClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let cataractClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let diabetesClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let drpredictionClassname = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiaRiskClassname = 'diseas-card-not-analyzed';
    let cardiovascularRiskClassname = 'diseas-card-not-analyzed';
    let laserClassname = componentsStrings.aeyedetailstable_notanalyzed;;
    let smokingclassname = componentsStrings.aeyedetailstable_notanalyzed;
    let iopclassname = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiafiiclassname = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiapmiclassname = componentsStrings.aeyedetailstable_notanalyzed;
    let dementiariskclassname = componentsStrings.aeyedetailstable_notanalyzed;
    let chAgeP = -1;
    let chGenderP = -1;
    let chFieledP = null;

    let chAgeClassname = '';
    let chGenderClassname = '';
    let chFieledClassname = '';

    let nodiagnosticdetails = this.props.diagnosticdetails == null;
    let counter = 0;
    
    if(this.props.diagnosticdetails) {
        [chFieledP, chAgeP, chGenderP, glaucomaP, armdP, mtmdrP, hrP, hrP_Systolic, hrP_Diastolic, pP, rvoP, cataractP, diabetesP, drpredictionP, dmeP, laserP, lipidTGP, lipidLDLP, smoking, iop, dementiafii, dementiapmi, dementiarisk, counter] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesP, this.props.bad_p);
        [glaucomaP] = this.getGlaucomaDescription(this.props.diagnosticdetails, this.props);

        [chFieledClassname, chAgeClassname, chGenderClassname, glaucomaClassname, armdClassname, mtmdrClassname, hrClassname, pClassname, rvoClassname, cataractClassname, diabetesClassname, drpredictionClassname, dmeClassname, laserClassname, lipidTGClassname, lipidLDLClassname, smokingclassname, iopclassname, dementiafiiclassname, dementiapmiclassname, dementiariskclassname] = this.getClassname(this.props.diagnosticdetails.diseasesP, this.props.bad_p);
        
        if(this.props.discexist == true) {
        }
    }

    let diseases = [];

    let disease1 = new Disease();
    disease1.name = componentsStrings.aeyedetailstable_dr;
    disease1.diseasId = "diagnosetool_summary_drR";
    disease1.diagnosis = mtmdrP;
    disease1.diagnosisId = "aeyedetailstable_dr_p";
    disease1.classname = mtmdrClassname;
    diseases.push(disease1);
    
    let disease2 = new Disease();
    disease2.name = componentsStrings.aeyedetailstable_glaucoma;
    disease2.diseasId = "diagnosetool_summary_gR2";
    disease2.diagnosis = glaucomaP;
    disease2.diagnosisId = "aeyedetailstable_g_p";
    disease2.classname = glaucomaClassname;        
    diseases.push(disease2);

    let disease3 = new Disease();
    disease3.name = componentsStrings.aeyedetailstable_arm;
    disease3.diseasId = "diagnosetool_summary_armdR";
    disease3.diagnosis = armdP;
    disease3.diagnosisId = "aeyedetailstable_arm_p";
    disease3.classname = armdClassname;
    diseases.push(disease3);

    let disease4 = new Disease();
    disease4.name = componentsStrings.aeyedetailstable_hr;
    disease4.diseasId = "diagnosetool_summary_hr";
    disease4.diagnosis = hrP;
    disease4.diagnosisId = "aeyedetailstable_hr";
    disease4.classname = hrClassname;
    diseases.push(disease4);

    let disease44 = new Disease();
    disease44.name = componentsStrings.aeyedetailstable_hrSystolic;
    disease44.diseasId = "diagnosetool_summary_hrP_Systolic";
    disease44.diagnosis = hrP_Systolic;
    disease44.diagnosisId = "aeyedetailstable_hrP_Systolic";
    disease44.classname = hrClassname;
    diseases.push(disease44);

    let disease444 = new Disease();
    disease444.name = componentsStrings.aeyedetailstable_hrDiastolic;
    disease444.diseasId = "diagnosetool_summary_hrP_Diastolic";
    disease444.diagnosis = hrP_Diastolic;
    disease444.diagnosisId = "aeyedetailstable_hrP_Diastolic";
    disease444.classname = hrClassname;
    diseases.push(disease444);

    let disease5 = new Disease();
    disease5.name = componentsStrings.aeyedetailstable_p;
    disease5.diseasId = "diagnosetool_summary_pR";
    disease5.diagnosis = pP;
    disease5.diagnosisId = "aeyedetailstable_p_p";
    disease5.classname = pClassname;
    diseases.push(disease5);

    let disease6 = new Disease();
    disease6.name = componentsStrings.aeyedetailstable_rvo;
    disease6.diseasId = "diagnosetool_summary_rvoR";
    disease6.diagnosis = rvoP;
    disease6.diagnosisId = "aeyedetailstable_rvo_p";
    disease6.classname = rvoClassname;
    diseases.push(disease6);

    let disease7 = new Disease();
    disease7.name = componentsStrings.aeyedetailstable_dme;
    disease7.diseasId = "diagnosetool_summary_dmeR";
    disease7.diagnosis = dmeP;
    disease7.diagnosisId = "aeyedetailstable_dme_p";
    disease7.classname = dmeClassname;
    diseases.push(disease7);

    let disease8 = new Disease();
    disease8.name = componentsStrings.aeyedetailstable_cataract;
    disease8.diseasId = "diagnosetool_summary_catR";
    disease8.diagnosis = cataractP;
    disease8.diagnosisId = "aeyedetailstable_cataract_p";
    disease8.classname = cataractClassname;
    diseases.push(disease8);

    let disease9 = new Disease();
    disease9.name = componentsStrings.aeyedetailstable_age;
    disease9.diseasId = "diagnosetool_summary_AgeR";
    disease9.diagnosis = chAgeP;
    disease9.diagnosisId = "aeyedetailstable_age";
    disease9.classname = chAgeClassname;
    diseases.push(disease9);

    let disease10 = new Disease();
    disease10.name = componentsStrings.aeyedetailstable_gender;
    disease10.diseasId = "diagnosetool_summary_GenderR";
    disease10.diagnosis = chGenderP;
    disease10.diagnosisId = "aeyedetailstable_gender";
    disease10.classname = chGenderClassname;
    diseases.push(disease10);

    let disease11 = new Disease();
    disease11.name = componentsStrings.aeyedetailstable_diabetes;
    disease11.diseasId = "diagnosetool_summary_diabetesR";
    disease11.diagnosis = diabetesP;
    disease11.diagnosisId = "aeyedetailstable_diabetes_p";
    disease11.classname = diabetesClassname;
    diseases.push(disease11);

    let disease12 = new Disease();
    disease12.name = componentsStrings.aeyedetailstable_drp;
    disease12.diseasId = "diagnosetool_summary_drpredictionR";
    disease12.diagnosis = drpredictionP;
    disease12.diagnosisId = "aeyedetailstable_drprediction_p";
    disease12.classname = drpredictionClassname;
    diseases.push(disease12);

    //let disease13 = new Disease();
    //disease13.name = componentsStrings.aeyedetailstable_dementia;
    //disease13.diseasId = "diagnosetool_summary_dementiaR";
    //disease13.diagnosis = dementiaP;
    //disease13.diagnosisId = "aeyedetailstable_dementia_p";
    //disease13.classname = !nodiagnosticdetails ? dementiaRiskClassname : '';
    //diseases.push(disease13);

    let disease14 = new Disease();
    disease14.name = componentsStrings.aeyedetailstable_cardiovascular;
    disease14.diseasId = "diagnosetool_summary_cardiovascularR";
    disease14.diagnosis = cardiovascularP;
    disease14.diagnosisId = "aeyedetailstable_cardiovascular_p";
    disease14.classname = !nodiagnosticdetails ? cardiovascularRiskClassname : '';
    diseases.push(disease14);

    let disease15 = new Disease();
    disease15.name = componentsStrings.aeyedetailstable_laser;
    disease15.diseasId = "diagnosetool_summary_laserR";
    disease15.diagnosis = laserP;
    disease15.diagnosisId = "aeyedetailstable_laser_p";
    disease15.classname = !nodiagnosticdetails ? laserClassname : '';
    diseases.push(disease15);

    let disease16 = new Disease();
    disease16.name = componentsStrings.aeyedetailstable_lipidTG;
    disease16.diseasId = "diagnosetool_summary_lipidTGR";
    disease16.diagnosis = lipidTGP;
    disease16.diagnosisId = "aeyedetailstable_lipidTG_p";
    disease16.classname = lipidTGClassname;
    diseases.push(disease16);

    let disease17 = new Disease();
    disease17.name = componentsStrings.aeyedetailstable_lipidLDL;
    disease17.diseasId = "diagnosetool_summary_lipidLDLR";
    disease17.diagnosis = lipidLDLP;
    disease17.diagnosisId = "aeyedetailstable_lipidLDL_p";
    disease17.classname = lipidLDLClassname;
    diseases.push(disease17);

    let disease18 = new Disease();
    disease18.name = componentsStrings.aeyedetailstable_smoking;
    disease18.diseasId = "diagnosetool_summary_smoking";
    disease18.diagnosis = smoking;
    disease18.diagnosisId = "aeyedetailstable_smoking";
    disease18.classname = smokingclassname;
    diseases.push(disease18);

    let disease19 = new Disease();
    disease19.name = componentsStrings.aeyedetailstable_iop;
    disease19.diseasId = "diagnosetool_summary_iop";
    disease19.diagnosis = iop;
    disease19.diagnosisId = "aeyedetailstable_iop";
    disease19.classname = iopclassname;
    diseases.push(disease19);

    let disease20 = new Disease();
    disease20.name = componentsStrings.aeyedetailstable_dementia_fii;
    disease20.diseasId = "diagnosetool_summary_dementiafii";
    disease20.diagnosis = dementiafii;
    disease20.diagnosisId = "aeyedetailstable_dementiafii";
    disease20.classname = dementiafiiclassname;
    diseases.push(disease20);
    let disease21 = new Disease();
    disease21.name = componentsStrings.aeyedetailstable_dementia_pmi;
    disease21.diseasId = "diagnosetool_summary_dementiapmi";
    disease21.diagnosis = dementiapmi;
    disease21.diagnosisId = "aeyedetailstable_dementiapmi";
    disease21.classname = dementiapmiclassname;
    diseases.push(disease21);
    let disease22 = new Disease();
    disease22.name = componentsStrings.aeyedetailstable_dementia_risk;
    disease22.diseasId = "diagnosetool_summary_dementiarisk";
    disease22.diagnosis = dementiarisk;
    disease22.diagnosisId = "aeyedetailstable_dementiarisk";
    disease22.classname = dementiariskclassname;
    diseases.push(disease22);

    diseases.sort((a, b) => { 

        if(a.diagnosis !== 'Not Analyzed' && b.diagnosis === 'Not Analyzed') {
            return -1;
        } else if (a.diagnosis === 'Not Analyzed' && b.diagnosis !== 'Not Analyzed') {
            return 1;
        } else {
            // return 0;    // No change in order
            return a.name.localeCompare(b.name);
        }
    });

    return (
        <React.Fragment>
        
            <div className="diseas-card bg-black bottom-border fs20 lh24 white pt-2 mt-1 pb-2 mb-1">
                {counter} {" FINDING "}
            </div>

            {this.getDiseasesComponent(diseases, nodiagnosticdetails)}

        </React.Fragment>
    );

  }


  getDiseasesComponent(diseases, nodiagnosticdetails) {

    return diseases.map((row, index) => {

        const { name, diseasId, diagnosis, diagnosisId, classname} = row;

        return (
            <DiseasCard diseasId={diseasId} diseasName={name} diseasDiagnosis={diagnosis} 
                diagnosisId ={diagnosisId} healtyOrSickClassname={!nodiagnosticdetails ? classname : ''} nodiagnosticdetails={nodiagnosticdetails}>
            </DiseasCard>
        )
    })
  }

}

export default DiseasesList;