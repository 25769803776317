import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import componentsStrings from "../../Messages/Components";
import { format } from 'react-string-format';
import messages from "../../Messages/Messages";

class AEYEDiagnosedDetails extends Component {
    state = {
        usertype: ""
    };

    componentDidMount() {

        this.setState({
            usertype: this.props.usertype
        })
    }
    
    getDiseasesDescription(diseases, bad) {
        let glaucoma = componentsStrings.aeyedetailstable_notanalyzed;
        let armd = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdr = componentsStrings.aeyedetailstable_notanalyzed;
        let hr = componentsStrings.aeyedetailstable_notanalyzed;
        let p = componentsStrings.aeyedetailstable_notanalyzed;
        let rvo = componentsStrings.aeyedetailstable_notanalyzed;
        let dme = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidTG = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidLDL = componentsStrings.aeyedetailstable_notanalyzed;
        let smoking = componentsStrings.aeyedetailstable_notanalyzed;
        let other = componentsStrings.aeyedetailstable_notanalyzed;
        let cataract = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetes = componentsStrings.aeyedetailstable_notanalyzed;
        let drprediction = componentsStrings.aeyedetailstable_notapplicable;

        let chAge = null;
        let chGender = null;
        let chFieled = null;

        let LipidTGPrediction = null;
        let LipidLDLPrediction = null;
        let smokingPrediction = null;
        let risk_group = null;
        let risk_percent = null;

        if(diseases && bad == false) {
            for (const [index, disease] of diseases.entries()) {
                if(disease.diseasename == "diagDRPrediction") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        risk_group = chjsonresults.risk_group;
                        risk_percent = (chjsonresults.risk_percent * 100);
                    }
                }
                if(disease.diseasename == "diagLipid") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        LipidTGPrediction = chjsonresults.predictionTG;
                        LipidLDLPrediction = chjsonresults.predictionLDL;
                    }
                }
                if(disease.diseasename == "diagSmoking") {
                    if(disease.chresults != null) {
                        const chjsonresults = JSON.parse(disease.chresults);
                        smokingPrediction = chjsonresults.prediction_indedx;
                    }
                }
            }
            for (const [index, disease] of diseases.entries()) {
                switch(disease.diseasename) {
                    case "diagDiabeticRetinopathy":
                        if(disease.diagnosis == 5) {
                            mtmdr =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            mtmdr = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_drsuspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            mtmdr =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagAgeRelatedMacularDegeneration":
                        if(disease.diagnosis == 5) {
                            armd =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            armd = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            armd =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagHypertensiveRetinopathy":
                        if(disease.diagnosis == 5) {
                            hr =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            hr = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            hr =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagPapilledema":
                        if(disease.diagnosis == 5) {
                            p =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            p = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            p =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagRetinalVascularOcclusion":
                        if(disease.diagnosis == 5) {
                            rvo =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            rvo = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            rvo =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagDME":
                        if(disease.diagnosis == 5) {
                            dme =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            dme = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            dme =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagLipid":
                        if(LipidTGPrediction == null || LipidLDLPrediction == null) {
                            lipidTG =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                            lipidLDL =<label className="gray_3">{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            lipidTG = <label className="orange">{LipidTGPrediction}</label>
                            lipidLDL = <label className="orange">{LipidLDLPrediction}</label>
                        }
                        break;
                    case "diagSmoking":
                            if(smokingPrediction == null) {
                                smoking =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                            } else {
                                if(smokingPrediction == 0) {
                                    smoking = <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_smoking_0}</label>
                                }
                                if(smokingPrediction == 1) {
                                    smoking = <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_smoking_1}</label>
                                }
                                if(smokingPrediction == 2) {
                                    smoking = <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_smoking_2}</label>
                                }
                                if(smokingPrediction == 3) {
                                    smoking = <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_smoking_3}</label>
                                }
                                if(smokingPrediction == 4) {
                                    smoking = <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_smoking_4}</label>
                                }
                            }
                        break;
                    case "diagGlaucoma":
                        if(disease.diagnosis == 5) {
                            glaucoma =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            glaucoma = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            glaucoma =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagAge":
                        if(disease.chresults != null) {
                            const chjsonresults = JSON.parse(disease.chresults);
                            let theage = parseFloat(chjsonresults.age_prediction).toFixed(1)
                            chAge = <label style={{color: 'green'}}>{theage}</label>
                        }
                        break;
                    case "diagGender":
                        if(disease.chresults != null) {
                            const chjsonresults = JSON.parse(disease.chresults);
                            chGender = <label style={{color: 'green'}}>{chjsonresults.gender_prediction}</label>
                        }
                        break;
                    case "diagField":
                        if(disease.chresults != null) {
                            chFieled = JSON.parse(disease.chresults);
                        }
                        break;
                    case "diagOther":
                        if(disease.diagnosis == 5) {
                            other =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            other = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            other =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagCataract":
                        if(disease.diagnosis == 5) {
                            cataract =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else {
                            cataract = (disease.diagnosis == 1) ? <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label> : <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            cataract =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                    case "diagDiabetes":
                        if(disease.diagnosis == 5 || risk_group == null || risk_percent == null) {
                            diabetes =<label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_failedtodiagnose}</label>
                        } else if(disease.diagnosis == 1) {
                            let thedrp = format(componentsStrings.aeyedetailstable_drprediction, risk_group, risk_percent);
                            diabetes = <label style={{color: 'red'}}>{componentsStrings.aeyedetailstable_suspiciousfinding}</label>
                            if(risk_group.toLowerCase() == 'low') {
                                drprediction = <label style={{color: 'green'}}>{thedrp}</label>
                            } else if(risk_group.toLowerCase() == 'medium') {
                                drprediction = <label style={{color: 'orange'}}>{thedrp}</label>
                            } else {
                                drprediction = <label style={{color: 'red'}}>{thedrp}</label>
                            }
                        } else {
                            diabetes = <label style={{color: 'green'}}>{componentsStrings.aeyedetailstable_nopathologicalfinding}</label>
                        }
                        if(disease.diagnosis == -1) {
                            diabetes =<label>{messages.table_desisdetails_dataencrypted}</label>
                            drprediction =<label>{messages.table_desisdetails_dataencrypted}</label>
                        }
                        break;
                }
            }
        }
        return [chFieled, chAge, chGender, glaucoma, armd, mtmdr, hr, p, rvo, other, cataract, diabetes, drprediction, dme, lipidTG, lipidLDL, smoking];
    }

    render() {

        let glaucomaL = componentsStrings.aeyedetailstable_notanalyzed;
        let glaucomaR = componentsStrings.aeyedetailstable_notanalyzed;
        let glaucomaP = componentsStrings.aeyedetailstable_notanalyzed;
        let armdL = componentsStrings.aeyedetailstable_notanalyzed;
        let armdR = componentsStrings.aeyedetailstable_notanalyzed;
        let armdP = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdrL = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdrR = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdrP = componentsStrings.aeyedetailstable_notanalyzed;
        let hrL = componentsStrings.aeyedetailstable_notanalyzed;
        let hrR = componentsStrings.aeyedetailstable_notanalyzed;
        let hrP = componentsStrings.aeyedetailstable_notanalyzed;
        let pL = componentsStrings.aeyedetailstable_notanalyzed;
        let pR = componentsStrings.aeyedetailstable_notanalyzed;
        let pP = componentsStrings.aeyedetailstable_notanalyzed;
        let rvoL = componentsStrings.aeyedetailstable_notanalyzed;
        let rvoR = componentsStrings.aeyedetailstable_notanalyzed;
        let rvoP = componentsStrings.aeyedetailstable_notanalyzed;
        let dmeL = componentsStrings.aeyedetailstable_notanalyzed;
        let dmeR = componentsStrings.aeyedetailstable_notanalyzed;
        let dmeP = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidTGL = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidTGR = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidLDLL = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidLDLR = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidTGP = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidLDLP = componentsStrings.aeyedetailstable_notanalyzed;
        let cataractL = componentsStrings.aeyedetailstable_notanalyzed;
        let cataractR = componentsStrings.aeyedetailstable_notanalyzed;
        let cataractP = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetesL = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetesR = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetesP = componentsStrings.aeyedetailstable_notanalyzed;
        let drpredictionL = componentsStrings.aeyedetailstable_notanalyzed;
        let drpredictionR = componentsStrings.aeyedetailstable_notanalyzed;
        let drpredictionP = componentsStrings.aeyedetailstable_notanalyzed;
        let otherL = componentsStrings.aeyedetailstable_notanalyzed;
        let otherR = componentsStrings.aeyedetailstable_notanalyzed;
        let otherP = componentsStrings.aeyedetailstable_notanalyzed;
        let smoking = componentsStrings.aeyedetailstable_notanalyzed;

        let glaucomaL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let glaucomaR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let glaucomaP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let armdL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let armdR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let armdP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdrL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdrR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let mtmdrP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let hrL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let hrR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let hrP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let pL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let pR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let pP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let rvoL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let rvoR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let rvoP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let dmeL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let dmeR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let dmeP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let lipidP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let cataractL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let cataractR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let cataractP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetesL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetesR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let diabetesP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let drpredictionL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let drpredictionR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let drpredictionP_two = componentsStrings.aeyedetailstable_notanalyzed;
        let otherL_d = componentsStrings.aeyedetailstable_notanalyzed;
        let otherR_d = componentsStrings.aeyedetailstable_notanalyzed;
        let otherP_two = componentsStrings.aeyedetailstable_notanalyzed;

        let chDementiaP = componentsStrings.aeyedetailstable_notanalyzed;
        let chLaserP = componentsStrings.aeyedetailstable_notanalyzed;
        let chCardiovascularP = componentsStrings.aeyedetailstable_notanalyzed;

        let chAgeL = -1, chAgeR = -1, chAgeP = -1, chAgeL_d = -1, chAgeR_d = -1, chAgeP_two = -1;
        let chGenderL = -1, chGenderR = -1, chGenderP = -1, chGenderL_d = -1, chGenderR_d = -1, chGenderP_two = -1;
        let chFieledL = null, chFieledR = null, chFieledP = null, chFieledL_d = null, chFieledR_d = null, chFieledP_two = null;

        if(this.props.diagnosticdetails) {
            //[chFieledL, chAgeL, chGenderL, glaucomaL, armdL, mtmdrL, hrL, pL, rvoL, otherL, cataractL, diabetesL, drpredictionL, dmeL, lipidTGL, lipidLDLL] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesL, this.props.bad_l);
            //[chFieledR, chAgeR, chGenderR, glaucomaR, armdR, mtmdrR, hrR, pR, rvoR, otherR, cataractR, diabetesR, drpredictionR, dmeR, lipidTGR, lipidLDLR] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesR, this.props.bad_r);
            [chFieledP, chAgeP, chGenderP, glaucomaP, armdP, mtmdrP, hrP, pP, rvoP, otherP, cataractP, diabetesP, drpredictionP, dmeP, lipidTGP, lipidLDLP, smoking] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesP, this.props.bad_p);

            if(this.props.discexist == true) {
                [chFieledL_d, chAgeL_d, chGenderL_d, glaucomaL_d, armdL_d, mtmdrL_d, hrL_d, pL_d, rvoL_d, otherL_d, cataractL_d, diabetesL_d, drpredictionL_d, dmeL_d, lipidL_d] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesL_d, this.props.bad_l_d);
                [chFieledR_d, chAgeR_d, chGenderR_d, glaucomaR_d, armdR_d, mtmdrR_d, hrR_d, pR_d, rvoR_d, otherR_d, cataractR_d, diabetesR_d, drpredictionR_d, dmeR_d, lipidR_d] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesR_d, this.props.bad_r_d);
                [chFieledP_two, chAgeP_two, chGenderP_two, glaucomaP_two, armdP_two, mtmdrP_two, hrP_two, pP_two, rvoP_two, otherP_two, cataractP_two, diabetesP_two, drpredictionP_two, dmeP_two, lipidP_two] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesP_two, this.props.bad_p);
            }
        }
        let msg1 = "";
        let msg2 = "";
        if(chFieledP != null) {
            msg1 = format(componentsStrings.aeyedetailstable_field_title, chFieledP.image_field_1);
            msg2 = format(componentsStrings.aeyedetailstable_field_title, chFieledP.image_field_2);
        }
        return (
            <React.Fragment>
                <Row>

                    <Col md={4}>
                    
                        <Table className="table table-borderless" id="aeyedetailstable_results">

                            <thead>
                                <tr>
                                    <th style={{width: '60%'}}>{componentsStrings.aeyedetailstable_condition_th}</th>
                                    <th style={{width: '40%'}}>{componentsStrings.aeyedetailstable_patientresult_th}</th>
                                </tr>
                            </thead>

                            <tbody style={{fontSize: '14px'}}>
                                <tr id="diagnosetool_summary_drR">
                                    <td>{componentsStrings.aeyedetailstable_dr}</td>
                                    {this.props.discexist == false && <td id="aeyedetailstable_dr_p">{mtmdrP}</td>}
                                    {this.props.discexist && <td id="aeyedetailstable_dr_ptwo">{mtmdrP_two}</td>}
                                </tr>
                                {glaucomaP != null && <tr id="diagnosetool_summary_gR2">
                                    <td>{componentsStrings.aeyedetailstable_glaucoma}</td>
                                    {this.props.discexist == false && <td>{glaucomaP}</td>}
                                    {this.props.discexist && <td>{glaucomaP_two}</td>}
                                </tr>}
                                <tr id="diagnosetool_summary_armdR">
                                    <td>{componentsStrings.aeyedetailstable_arm}</td>
                                    {this.props.discexist == false && <td>{armdP}</td>}
                                    {this.props.discexist && <td>{armdP_two}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_hrR">
                                    <td>{componentsStrings.aeyedetailstable_hr}</td>
                                    {this.props.discexist == false && <td>{hrP}</td>}
                                    {this.props.discexist && <td>{hrP_two}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_pR">
                                    <td>{componentsStrings.aeyedetailstable_p}</td>
                                    {this.props.discexist == false && <td>{pP}</td>}
                                    {this.props.discexist && <td>{pP_two}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_rvoR">
                                    <td>{componentsStrings.aeyedetailstable_rvo}</td>
                                    {this.props.discexist == false && <td>{rvoP}</td>}
                                    {this.props.discexist && <td>{rvoP_two}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_dmeR">
                                    <td>{componentsStrings.aeyedetailstable_dme}</td>
                                    {this.props.discexist == false && <td>{dmeP}</td>}
                                    {this.props.discexist && <td>{dmeP_two}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_lipidTGR">
                                    <td>{componentsStrings.aeyedetailstable_lipidTG}</td>
                                    {this.props.discexist == false && <td>{lipidTGP}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_lipidLDLR">
                                    <td>{componentsStrings.aeyedetailstable_lipidLDL}</td>
                                    {this.props.discexist == false && <td>{lipidLDLP}</td>}
                                </tr>
                                <tr id="diagnosetool_summary_smoking">
                                    <td>{componentsStrings.aeyedetailstable_smoking}</td>
                                    {this.props.discexist == false && <td>{smoking}</td>}
                                </tr>

                                {cataractP != null && <tr id="diagnosetool_summary_catR">
                                    <td>{componentsStrings.aeyedetailstable_cataract}</td>
                                    {this.props.discexist == false && <td>{cataractP}</td>}
                                    {this.props.discexist && <td>{cataractP_two}</td>}
                                </tr>}
                                
                                {diabetesP != null && <tr id="diagnosetool_summary_diabetesR">
                                    <td>{componentsStrings.aeyedetailstable_diabetes}</td>
                                    {this.props.discexist == false && <td id="aeyedetailstable_diabetes_p">{diabetesP}</td>}
                                    {this.props.discexist && <td id="aeyedetailstable_diabetes_ptwo">{diabetesP_two}</td>}
                                </tr>}
                                {drpredictionP != null && <tr id="diagnosetool_summary_drpredictionR">
                                    <td>{componentsStrings.aeyedetailstable_drp}</td>
                                    {this.props.discexist == false && <td id="aeyedetailstable_drprediction_p">{drpredictionP}</td>}
                                    {this.props.discexist && <td id="aeyedetailstable_drprediction_ptwo">{drpredictionP_two}</td>}
                                </tr>}

                                {<tr id="diagnosetool_summary_Dementia RiskR">
                                    <td>{"Dementia Risk"}</td>
                                    <td>{chDementiaP}</td>
                                </tr>}
                                {<tr id="diagnosetool_summary_CardiovascularR">
                                    <td>{"Cardiovascular Risk"}</td>
                                    <td>{chCardiovascularP}</td>
                                </tr>}
                                {<tr id="diagnosetool_summary_LaserR">
                                    <td>{"Laser"}</td>
                                    <td>{chLaserP}</td>
                                </tr>}

                                {chAgeP != null && <tr id="diagnosetool_summary_AgeR">
                                    <td>{componentsStrings.aeyedetailstable_age}</td>
                                    <td>{chAgeP}</td>
                                </tr>}
                                {chGenderP != null && <tr id="diagnosetool_summary_GenderR">
                                    <td>{componentsStrings.aeyedetailstable_gender}</td>
                                    <td>{chGenderP}</td>
                                </tr>}
                                
                                {this.props.usertype != 'C' &&
                                    <tr id="diagnosetool_summary_oR">
                                        <td>{componentsStrings.aeyedetailstable_other}</td>
                                        {this.props.discexist == false && <td>{otherP}</td>}
                                        {this.props.discexist && <td>{otherP_two}</td>}
                                    </tr>
                                }


                                {this.props.diagnosticdetails && this.props.diagnosticdetails.diagnoseby != 'Algorithm' && this.props.diagnosticdetails && 
                                    <tr>
                                        <td>{componentsStrings.aeyedetailstable_commencts_th}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{this.props.diagnosticdetails.comments}</td>
                                    </tr>
                                }

                                
                            </tbody>
                    
                        </Table>
                                    
                    </Col >

                    <Col md={4}>
                        
                        <Table className="table table-borderless">
                            <div style={{paddingBottom: '15px', textAlign: '-webkit-center'}}>
                                <thead>
                                    <tr>
                                        <th>{componentsStrings.aeyedetailstable_right_column}</th>
                                    </tr>
                                </thead>
                            </div>

                            {this.props.diagnosticdetails && this.props.diagnosticdetails.image_content_file_r != null &&
                                <tbody>
                                    <tr style={{textAlign: 'center', wordBreak: 'break-all'}}>
                                        <img src={this.props.srcR} style={{width: '100%', height: '100%'}}></img>
                                        {this.props.diagstate != -1 && this.props.fileNameRight + ((this.props.bad_r == 1) ? ' - ' + componentsStrings.aeyedetailstable_iiq : ' - ' + componentsStrings.aeyedetailstable_noiiq)}
                                        {this.props.diagstate == -1 && this.props.fileNameRight}
                                    </tr>
                                </tbody>}
                        </Table>
                        {this.props.diagnosticdetails && this.props.diagnosticdetails.image_content_file_r != null &&
                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                                {chFieledP != null && <p id="diagnosetool_summary_fieldR" style={{margin: 'auto', color: '#ccc'}}>
                                    {msg1}
                                </p>}
                            </Row>}
                        {this.props.diagnosticdetails && this.props.diagnosticdetails.image_content_file_r != null &&
                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                            {chFieledP != null && <p id="diagnosetool_summary_sideR" style={{margin: 'auto', color: '#ccc'}}>
                                {componentsStrings.aeyedetailstable_side_title + chFieledP.image_side_2}
                            </p>}
                            </Row>}
                    </Col >

                    <Col md={4}>

                        <Table className="table table-borderless">
                            <div style={{paddingBottom: '15px', textAlign: '-webkit-center'}}>
                                <thead>
                                    <tr>
                                        <th>{componentsStrings.aeyedetailstable_left_column}</th>
                                    </tr>
                                </thead>
                            </div>
                            
                            {this.props.diagnosticdetails && this.props.diagnosticdetails.image_content_file_l != null &&
                                <tbody>
                                    <tr style={{textAlign: 'center', wordBreak: 'break-all'}}>
                                        <img src={this.props.srcL} style={{width: '100%', height: '100%'}}></img>
                                        {this.props.diagstate != -1 && this.props.fileNameLeft + ((this.props.bad_l == 1) ? ' - ' + componentsStrings.aeyedetailstable_iiq : ' - ' + componentsStrings.aeyedetailstable_noiiq)}
                                        {this.props.diagstate == -1 &&  this.props.fileNameLeft}
                                    </tr>
                                </tbody>}                            
                        </Table>
                        
                        {this.props.diagnosticdetails && this.props.diagnosticdetails.image_content_file_l != null &&
                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                                {chFieledP != null && <p id="diagnosetool_summary_fieldL" style={{margin: 'auto', color: '#ccc'}}>
                                    {msg2}
                                </p>}
                            </Row>}

                        {this.props.diagnosticdetails && this.props.diagnosticdetails.image_content_file_l != null &&
                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                            {chFieledP != null && <p id="diagnosetool_summary_sideL" style={{margin: 'auto', color: '#ccc'}}>
                                {componentsStrings.aeyedetailstable_side_title + chFieledP.image_side_1}
                            </p>}
                            </Row>}
                    </Col >

                </Row>
            </React.Fragment>
        );
    }
}

export default AEYEDiagnosedDetails;